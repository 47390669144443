/* Override system elems */

body {
  font-family: 'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    /* 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
  overflow-x: hidden; /* To avoid graph tooltip from borking */

  /* scroll-snap-type: block; */
  scroll-snap-type: both proximity;
  scroll-snap-stop: always;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Mermaid */
pre {
  background-color: transparent !important;
}
