/* Router transition classes */
.switch-wrapper {
  position: relative;
  /* max-width: 100vw; */
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  max-height: calc(100vh - 128px);
  /* max-width: 100vw;
      overflow: 'hidden'; */
}

@media screen and (min-width: 700px) {
  .switch-wrapper > div {
    max-height: calc(100vh - 64px);
  }
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
  width: 100%;
  /* overflow: hidden; */
}

/* Fluid typography: */
@media screen and (min-width: 320px) {
  .fluid-font {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  .fluid-font {
    font-size: 22px;
  }
}

/* Mapp stuff: rlayers & ol */
.RControl-RCustom--button-top-first {
  margin-left: 6px;
  margin-top: 6px;
}
.RControl-RCustom--button-top-second {
  margin-left: 6px;
  margin-top: 38px;
}
.RControl-RCustom--button-top-third {
  margin-left: 6px;
  bottom: 38px;
}
.RControl-ROverviewMap--map-bottom-right {
  bottom: 8px !important;
  right: 8px !important;
  top: auto !important;
  left: auto !important;
}
.RPopup--default {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  user-select: none;
  pointer-events: none;
  position: absolute;
  width: max-content;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  /* padding: 15px; */
  /* border-radius: 10px; */
  /* border: 1px solid #cccccc; */
}
