/* See index.css for component overrides */

/* Mui overrides */
.material-icons* {
  text-shadow: 0 0 0 rgb(255, 0, 0), 0 0 0 rgb(0, 255, 0), 0 0 0 rgb(0, 0, 255);
}
.material-icons:hover {
  text-shadow: 0.3px -0.1px 0.1px rgb(255, 0, 0), 0.2px 0.1px 0 rgb(0, 255, 0),
    -0.1px 0.2px 0 rgb(0, 0, 255);
  transition: 0.3s cubic-bezier(0.31, 148, 1, -281);
  /* transition: 0.7s cubic-bezier(0.5, 100, 0.5, -100); */
}

.glitch-text* {
  text-shadow: 0 0 0 rgb(255, 0, 0), 0 0 0 rgb(0, 255, 0), 0 0 0 rgb(0, 0, 255);
}
.glitch-text:hover {
  text-shadow: 0.3px -0.1px 0.1px rgb(255, 0, 0), 0.2px 0.1px 0 rgb(0, 255, 0),
    -0.1px 0.2px 0 rgb(0, 0, 255);
  transition: 0.3s cubic-bezier(0.31, 148, 1, -281);
  /* transition: 0.7s cubic-bezier(0.5, 100, 0.5, -100); */
}

/*
#flat-title* {
  text-shadow: 0 0 0 rgb(255, 0, 0), 0 0 0 rgb(0, 255, 0), 0 0 0 rgb(0, 0, 255);
}
#flat-title:not(:hover) {
  text-shadow: 0.3px -0.1px 0.1px rgb(255, 0, 0), 0.2px 0.1px 0 rgb(0, 255, 0),
    -0.1px 0.2px 0 rgb(0, 0, 255);
  transition: 0.3s cubic-bezier(0.31, 148, 1, -281);
}
*/

/* .Mui-selected {
  color: pink !important;
} */

/* Misc overrides */
.wmde-markdown {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

/* Slate */

.slate-MediaEmbedElement-resizable__twitter {
  min-height: 246px; /* Avoid Twitter collapse */
}

.slate-ImageElement-handleLeft,
.slate-MediaEmbedElement-handleLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 1.5rem;
  left: -0.75rem;
  margin-left: -0.75rem;
  padding-left: 0.75rem;
  user-select: none;
}

.slate-ImageElement-handleRight,
.slate-MediaEmbedElement-handleRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 1.5rem;
  right: -0.75rem;
  margin-right: -0.75rem;
  padding-right: 0.75rem;
  user-select: none;
  align-items: flex-end;
}

.slate-ImageElement-handleLeft:hover::after,
.slate-ImageElement-handleRight:hover::after,
.slate-MediaEmbedElement-handleLeft:hover::after,
.slate-MediaEmbedElement-handleRight:hover::after {
  content: ' ';
  width: 3px;
  height: 64px;
  border-radius: 6px;
  background-color: grey;
}
/*
.slate-SelectionArea {
}
.slate-selectable {
} */

.slate-selected {
  outline: 1px dotted black;
  margin-top: 4px;
  margin-bottom: 6px;
  /* padding: 3px 10px 3px; */
  /* padding-left: 10px;
  padding-right: 10px; */
  border-radius: 3px;
}

.slate-selectable:hover {
  outline: 1px dotted black;
  margin-top: 4px;
  margin-bottom: 6px;
  /* padding: 3px 10px 3px; */
  /* padding-left: 10px;
  padding-right: 10px; */
  border-radius: 3px;
  background-color: rgba(130, 166, 194, 0.4) !important;
}
.slate-selectable {
  transition: 0.6s all;
}
.slate-selection-area {
  background: rgba(51, 155, 85, 0.4);
  border: 1px solid rgba(51, 155, 85, 1);
}

/* Padding for slate-SelectionArea */
[data-slate-editor] {
  /* padding: 12px 12px 12px; */
  /* position: relative; */
}
.plate-draggable__container [data-slate-editor] {
  padding: 32px 24px 32px;
  /* position: relative; */
}

.slate-start-area {
  cursor: crosshair !important;
}
.slate-start-area-top {
  height: 32px !important;
}
.slate-start-area-right {
  width: 24px !important;
}
.slate-start-area-left {
  width: 24px !important;
}
.slate-start-area-bottom {
  height: 32px !important;
}
.slate-Draggable-dragHandle {
  cursor: grab !important;
}
.slate-Draggable-gutterLeft {
  /* TODO: Show vertical line? */
}

/* Markdown */

.ql-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.ql-toolbar {
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.23) !important;
  border-radius: 20px;
}

.ql-editor.ql-blank::before {
  color: lightgrey;
  font-style: normal;
}

.ql-disabled * {
  /* cursor: pointer !important; */
}

/* .ql-tooltip {
  z-index: 99999 !important;
} */

.wmde-markdown h1,
.wmde-markdown h2 {
  border: none !important;
}
/*
.editor-toolbar button {
  background-color: #444;
  border: 0px solid #555;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 18px;
}
.editor-toolbar button .active {
  border: 1px solid #555;
} */
/* .md-editor {
  background-color: inherit;
  color: inherit;
}
.md-editor-toolbar {
  background-color: inherit;
  color: inherit;
}

.w-md-editor {
  background-color: inherit;
  color: inherit;
}
.w-md-editor-toolbar {
  background-color: inherit;
  color: inherit;
}
.w-md-editor-text-input {
  background-color: inherit;
  color: inherit;
} */
